<template>
  <div>
    <v-btn flat icon color="secondary"
      v-for="socialMedia in socialMedias"
      :key="socialMedia.id"
      :href="`//${socialMedia.url}`"
      target="_blank"
      relopener="noopener">
      <v-icon>{{$vuetify.icons[socialMedia.icon]}}</v-icon>
    </v-btn>
  </div>
</template>

<script>
  import { socialMedias } from '@/utils/constants'

  export default {
    name: 'candy-bar',

    mounted() {
    },

    props: {

    },

    data() {
      return {
        socialMedias: socialMedias
      }
    },

    computed: {
      getClasses() {
        return {

        }
      },
    },

    methods: {

    }
  }
</script>

<style lang="stylus">

</style>