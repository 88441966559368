import axios from 'axios'
import jscookie from 'js-cookie'
import { AUTH_CSRF_TOKEN } from '@/api/auth'

// import Raven from 'raven-js'

// i18n stuff
// import i18n from '@/i18n'
// const locale = jscookie.get(COOKIE_LOCALE)
// axios.defaults.headers.common['Accept-Language'] = locale

function request(config) {
  if (!config && !config.url) {
    throw new Error('Must provide request config and url')
  }

  let request = axios.create({
    baseURL: process.env.VUE_APP_DRUPAL_API,
    timeout: 15000,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': jscookie.get(AUTH_CSRF_TOKEN)
    }
  })

  return request.request(config)
    .then(response => response)
    .catch(error => {
      let {
        response: {
          status,
          statusText,
          data: {
            message: meaningfulMessage,
            errors: [{
              title,
              detail: jsonApiError
            } = {}] = []
          } = {},
          request: {
            responseURL
          } = {}
        } = {},
        message,
      } = error

      let errorMessage = jsonApiError || meaningfulMessage || message

      if ((status === 401 || status === 403) && !responseURL.includes('logout')) {
        throw new Error(errorMessage)
        // Raven.captureException(errorMessage, { extra: error })
      }

      throw Object.assign(new Error(errorMessage), { status, statusText, message: errorMessage })
    })
}

export default request
