import Vue from 'vue'
import Router from 'vue-router'
const Home = () => import('@/views/Home')
const Support = () => import('@/views/Support')
const Trailer = () => import('@/views/Trailer')
const StoryMap = () => import('@/views/StoryMap')
const Contact = () => import('@/views/Contact')
const Connect = () => import('@/views/Connect')
const Join = () => import('@/views/Join')
const Press = () => import('@/views/Press')
const Host = () => import('@/views/Host')
const HomeScreening = () => import('@/views/HomeScreening')
const PublicScreening = () => import('@/views/PublicScreening')
const Make = () => import('@/views/Make')
const Watch = () => import('@/views/Watch')

import { PAGE_TITLES, PAGE_TITLE_SUFFIX, PAGE_DESCRIPTIONS } from '@/utils/constants'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/story-map/:path?',
      name: 'storyMap',
      component: StoryMap
    },
    {
      path: '/support',
      name: 'support',
      component: Support
    },
    {
      path: '/trailer',
      name: 'trailer',
      component: Trailer
    },
    {
      path: '/join-us',
      name: 'join',
      component: Join
    },
    {
      path: '/make/:id?',
      name: 'make',
      component: Make,
      props: true
    },
    {
      path: '/connect',
      name: 'connect',
      component: Connect
    },
    {
      path: '/contact-us',
      name: 'contact',
      component: Contact
    },
    {
      path: '/press',
      name: 'press',
      component: Press
    },
    {
      path: '/host',
      name: 'host',
      component: Host,
    },
    {
      path: '/watch',
      name: 'watch',
      component: Watch,
    },
    {
      path: '/host/private',
      name: 'home-screening',
      component: HomeScreening
    },
    {
      path: '/host/public',
      name: 'public-screening',
      component: PublicScreening
    },
  ],
})

router.beforeEach((to, from, next) => {
  const { name } = to

  document.title = (name && PAGE_TITLES[name]) ? PAGE_TITLES[name] : PAGE_TITLE_SUFFIX

  if (PAGE_DESCRIPTIONS[name]) {
    const existingTag = document.querySelector('[data-vue-description]')
    const tag = (existingTag)
      ? existingTag
      : document.createElement('meta')

    tag.setAttribute('data-vue-description', '')
    tag.setAttribute('name', 'description')
    tag.setAttribute('content', PAGE_DESCRIPTIONS[name])
    
    // only append it if it didn't exist before
    !existingTag && document.head.appendChild(tag)
  }

  next()
})

export default router
