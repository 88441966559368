import auth from '@/api/auth'
import mailchimp from '@/api/mailchimp'

const state = {}

const getters = {}

const actions = {
  async subscribeUser({}, payload) {
    try {
      const response = await mailchimp.subscribeUser(payload)

      return
    } catch(error) {
      const {
        response: {
          data: {
            detail,
            title
          } = {}
        } = {}
      } = error

      // TODO: better error handling - don't want errors directly from MailChimp
      console.error(detail)
      throw new Error(title)
    }
  },

  async getSessionToken({ commit, dispatch, getters}, payload) {
    try {
      await auth.getToken()

      return
    } catch(error) {
      throw new Error(error)
    }
  },
}

const mutations = {}

export default { state, getters, actions, mutations }