import Vue from 'vue'
import './plugins/vuetify'
import i18n from './plugins/i18n'
import './plugins/announcer'
import './plugins/gtm'
import './plugins/infiniteScroll'
import './plugins/recaptcha'
import './plugins/sentry'
import './plugins/youtubeEmbed'
import './stylus/main.styl'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import Components from './components/manifest'
import MappKitLeaflet from '@vue-mapp-kit/leaflet'

Vue.use(MappKitLeaflet, { store })
Vue.use(Vuelidate)
Vue.config.productionTip = false

Object.keys(Components).forEach(key => {
  Vue.component(`${key}`, Components[key])
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app');
