<template>
  <div class="number-label">
    <div class="number-label__number mr-2 accent--text">
      <span>{{ number }}</span>
    </div>
    <span class="number-label__label"><slot></slot></span>
  </div>
</template>

<script>
  export default {
    name: 'number-label',

    props: {
      number: [String, Number]
    }
  }
</script>

<style lang="stylus">
  .number-label
    font-size: 18px
    display: flex
    align-items: center
    font-weight: bold

    .number-label__number
      display: inline-flex
      justify-content: center
      align-items: center
      width: 30px
      height: 30px
      border-radius: 50%
      border: 1px solid
</style>