import Vue from 'vue'
import {
  UI_ADD_LOADING_STATE,
  UI_REMOVE_LOADING_STATE,
  DIALOG_ACTIVE_UPDATE,
  DIALOG_ACTIVE_CLEAR,
} from '../mutation-types'
import indexOf from 'lodash.indexof'
import union from 'lodash.union'

const state = {
  dialogActive: {},
  loadingStates: []
}

const getters = {
  isLoading: (state) => (value) => {
    return state.loadingStates.includes(value)
  },
  isCompleted: (state, getters) => (value) => {
    return getters.isLoading(value)
  }
}

const actions = {
  openDialog({ commit }, payload) {
    if (!payload && !payload.type) throw new Error('[action] openDialog: payload not correct shape')

    let promiseReturn;

    if (!payload.props) payload.props = {}

    if (payload.async) {
      promiseReturn = new Promise((resolve, reject) => {
        payload.props = Object.assign(payload.props, {
          commit: resolve,
          cancel: reject
        })
      })
    }

    commit(DIALOG_ACTIVE_UPDATE, payload)

    return promiseReturn
  },
  closeDialog({ commit }) {
    commit(DIALOG_ACTIVE_CLEAR)
  }
}

const mutations = {
  [UI_ADD_LOADING_STATE](state, value) {
    state.loadingStates = union(state.loadingStates, [value])
  },
  [UI_REMOVE_LOADING_STATE](state, value) {
    let workingCopy = state.loadingStates.slice()
    let index = indexOf(workingCopy, value)

    if (index !== -1) {
      workingCopy.splice(index, 1)
      state.loadingStates = workingCopy
    }
  },
  [DIALOG_ACTIVE_UPDATE](state, payload) {
    let { type, props = {} } = payload

    Vue.set(state.dialogActive, 'type', type)
    Vue.set(state.dialogActive, 'props', props)
  },
  [DIALOG_ACTIVE_CLEAR](state) {
    state.dialogActive = {}
  }
}

export default { state, getters, actions, mutations }
