import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

const DEBUG_SENTRY = false

if (process.env.NODE_ENV === 'production' || DEBUG_SENTRY) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_URL,
    integrations: [new Integrations.Vue({Vue, attachProps: true})],
    beforeSend(event) {
      // TODO: should notifications live here?
      console.error('Error Event', event)
  
      return event
    }
  });
} else {
  Vue.config.errorHandler = (err, vm, info) => {
    console.error(err)
    console.info({ vm, info })
  }
  
  window.onerror = function(message, source, lineno, colno, error) {
    console.error(message, error)
    console.info({
      source,
      lineno,
      colno
    })
  }
}