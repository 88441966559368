// ui
export const UI_ADD_LOADING_STATE = 'UI_ADD_LOADING_STATE'
export const UI_REMOVE_LOADING_STATE = 'UI_REMOVE_LOADING_STATE'
export const UI_ADD_FETCHED = 'UI_ADD_FETCHED'
export const UI_REMOVE_FETCHED = 'UI_REMOVE_FETCHED'
export const DIALOG_ACTIVE_UPDATE = 'DIALOG_ACTIVE_UPDATE'
export const DIALOG_ACTIVE_CLEAR = 'DIALOG_ACTIVE_CLEAR'

// content types
export const CONTENT_TYPE_DELETE = 'CONTENT_TYPE_DELETE'
export const CONTENT_TYPE_UPDATE = 'CONTENT_TYPE_UPDATE'

// views
export const VIEW_UPDATE = 'VIEW_UPDATE'