<template>
  <v-app :class="getGlobalClasses">
    <v-navigation-drawer
      temporary
      fixed
      v-model="drawer"
      app
      dark
      touchless
      color="primary"
      role="complimentary">
      <main-menu></main-menu>
    </v-navigation-drawer>

    <!-- TOOLBAR -->
    <v-toolbar
      dark
      fixed
      app
      color="primary"
      class="west-is-burning__toolbar"
      aria-label="Header Navigation"
    >
      <v-toolbar-side-icon @click.stop="drawer = !drawer" color="accent--text"></v-toolbar-side-icon>
      <v-toolbar-title>
        <router-link :to="{ name: 'home' }">
          <img src="/img/logos/The-West-is-Burning-logo.png" 
            alt="The West is Burning"
            title="The West is Burning"
            class="west-is-burning__toolbar-logo"/>
        </router-link>
      </v-toolbar-title>
    </v-toolbar>

  <!-- MAIN CONTENT WRAPPER -->
    <v-content id="main-content">
      <covid-alert v-if="showCovidAlert" />
      
      <v-container fluid style="height: 100%;">
        <transition name="fade-fast" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-content> 

    <!-- STICKY SCROLLING FOOTER -->
    <sticky-footer :override="stickyFooterOverride" class="west-is-burning__sticky">
      <v-layout
        justify-center
        row
        wrap>
        <v-flex xs4 sm3 md2 lg3 class="text-xs-center">
          <v-layout column fill-height>
            <router-link
              class="text-uppercase white--text"
              :to="{ name: 'host' }"
              id="host-sticky-link"
            >
              Host
            </router-link>

            <v-layout column justify-space-between align-center class="west-is-burning__sticky-more hidden-sm-and-down">
              <p id="host-sticky-desc">Public or private screening & discussions</p>
              <v-btn
                icon
                outline
                color="white white--text"
                :to="{ name: 'host' }"
                active-class=""
                aria-labelledby="host-sticky-link host-sticky-desc"
              >
                <v-icon>arrow_forward</v-icon>
              </v-btn>
            </v-layout>
          </v-layout>
        </v-flex>

        <v-flex xs4 sm3 md3 lg3 class="text-xs-center">
          <v-layout column fill-height>
            <router-link
              class="text-uppercase white--text"
              :to="{ name: 'make' }"
              id="make-sticky-link"
            >
              MAKE
            </router-link>

            <v-layout column justify-space-between align-center class="west-is-burning__sticky-more hidden-sm-and-down">
              <p id="make-sticky-desc">Short films from this documentary</p>
              <v-btn
                icon
                outline
                color="white white--text"
                :to="{ name: 'make' }"
                aria-labelledby="make-sticky-link make-sticky-desc"
              >
                <v-icon>arrow_forward</v-icon>
              </v-btn>
            </v-layout>
          </v-layout>
        </v-flex>

        <v-flex xs4 sm3 md3 lg3 class="text-xs-center">
          <v-layout column fill-height>
            <router-link
              class="text-uppercase white--text"
              :to="{ name: 'connect' }"
              id="connect-sticky-link"
            >
              Connect
            </router-link>

            <v-layout column justify-space-between align-center class="west-is-burning__sticky-more hidden-sm-and-down">
              <p id="connect-sticky-desc">Unite with organizations making a difference</p>
              <v-btn
                icon
                outline
                color="white white--text"
                :to="{ name: 'connect' }"
                aria-labelledby="connect-sticky-link connect-sticky-desc"
              >
                <v-icon>arrow_forward</v-icon>
              </v-btn>
            </v-layout>
          </v-layout>
        </v-flex>
      </v-layout>
    </sticky-footer> 

    <!-- REGULAR FOOTER -->
    <footer-info v-if="$route.name != 'make' && $route.name != 'storyMap'"></footer-info>
    <refresh-ui></refresh-ui>
  </v-app>
</template>

<script>
import CovidAlert from '@/components/_alerts/CovidAlert/CovidAlert'
import FooterInfo from '@/components/FooterInfo/FooterInfo'
import MainMenu from '@/components/MainMenu/MainMenu'
import RefreshUi from '@/components/RefreshUi/RefreshUi'
import { mapActions} from 'vuex'
import { COVID_BANNER_COOKIE } from '@/utils/constants'

import jscookie from 'js-cookie'

export default {
  created() {
    // TODO: race condition!!
    this.getSessionToken()
  },

  components: {
    CovidAlert,
    FooterInfo,
    MainMenu,
    RefreshUi
  },

  data() {
    return {
      drawer: null
    }
  },

  methods: {
    ...mapActions(['getSessionToken'])
  },

  computed: {
    getGlobalClasses() {
      return {
        'west-is-burning': true,
        [`west-is-burning--page-${this.$route.name}`]: true
      }
    },
    stickyFooterOverride() {
      const routeBlackList = [
        'join',
        'storyMap',
        'make'
      ]

      return !routeBlackList.includes(this.$route.name)
    },
    showCovidAlert () {
      const whitelist = [
        'host',
        'home-screening',
        'public-screening',
        'watch'
      ]

      return whitelist.includes(this.$route.name)
        && !jscookie.get(COVID_BANNER_COOKIE)
    }
  }
}
</script>

<style lang="stylus">
  .west-is-burning
    .v-toolbar__title
      margin-left: 5px !important

    .sticky-footer
      .v-btn
        font-size: 20px
    
    .west-is-burning__toolbar-logo
      max-width: 160px
      margin-top: 14px

    .west-is-burning__toolbar
      z-index: 4
      max-width: 100vw

  .west-is-burning__sticky
    padding: 5px
    .v-btn
      font-weight: bold
    a
      font-weight: bold
      font-size: 20px
      text-decoration: none
      padding: 8px 0px

  .west-is-burning__sticky-more
    margin-top: -10px

    p
      color: $theme.secondary
      font-size: 14px
      margin-bottom: 5px
        
  .application.theme--light .west-is-burning__sticky
    @media(min-width: $md-breakpoint)
      .v-btn:hover:before
        background-color: transparent !important
</style>
