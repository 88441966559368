import axios from 'axios'
import {
  MAIL_CHIMP_SSM_KEY,
  MAIL_CHIMP_LIST
} from '@/utils/constants'
import { encodeAppName } from '@/utils/general'

const mailchimp = {
  subscribeUser({ name, email }) {
    if (!email) throw new Error('[subscribeUser] missing required parameters')

    return axios.post(`${process.env.VUE_APP_CALS_SERVICES}/mailchimp/subscribe-user?app=westisburning`, {
      emailAddress: email,
      name,
      key: MAIL_CHIMP_SSM_KEY,
      listId: MAIL_CHIMP_LIST
    }, {
      headers: {
        authorization: encodeAppName('westisburning')
      }
    })
      .then(response => {
        let { data = {} } = response
        
        return data
      })
  }
}

export default mailchimp
