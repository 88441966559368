import Vue from 'vue'

// TODO: content type events
const actions = {
  async generalEvent({}, payload) {
    try {
      let { category, action, label, value } = payload

      if (!category) throw new Error('General Event requires a category')

      Vue.gtm.trackEvent({
        event: 'general-event',
        category, // dataLayer.GACategory
        action, // dataLayer.GAAction
        label: (typeof label === 'object') ? JSON.stringify(label) : label, // dataLayer.GALabel
        value, // dataLayer.GAValue
      })
    } catch(e) {
      throw e
    }
  },
  async contentTypeEvent({ commit, dispatch, getters}, payload) {
    // try {
    //   let { type, action, label, value } = payload

    //   if (!type) throw new Error('Content Type Event requires a type')

    //   // e.g. type --> category --> target --> dataLayer.WidgetType
    //   Vue.gtm.trackEvent({
    //     event: 'widget-interaction',
    //     category: type, // dataLayer.WidgetType
    //     action, // dataLayer.WidgetAction
    //     label: (typeof label === 'object') ? JSON.stringify(label) : label, // dataLayer.WidgetLabel
    //     value, // dataLayer.WidgetValue
    //   })
    // } catch(e) {
    //    throw e
    // }
  },
}

export default { actions }
