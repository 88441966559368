<template>
  <v-btn 
    color="accent" 
    depressed
    href="https://manage.kmail-lists.com/subscriptions/subscribe?a=UbCwF6&g=W6DyQ2"
    target="_blank"
  >
    <slot>
      Receive Updates
    </slot>
    <v-icon right>open_in_new</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'KlayvioBtn'
}
</script>
