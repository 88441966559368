import Alert from './Alert/Alert'
import FilterList from './FilterList/FilterList'
import NumberLabel from './NumberLabel/NumberLabel'
import RecaptchaPolicy from './RecaptchaPolicy/RecaptchaPolicy'
import StickyFooter from './StickyFooter/StickyFooter'
import YoutubeVideo from './YoutubeVideo/YoutubeVideo'
import ViewWrapper from './ViewWrapper/ViewWrapper'

export default {
  Alert,
  FilterList,
  NumberLabel,
  RecaptchaPolicy,
  StickyFooter,
  ViewWrapper,
  YoutubeVideo
}
