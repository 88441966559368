import Vue from 'vue'
import {
  CONTENT_TYPE_DELETE,
  CONTENT_TYPE_UPDATE,
  UI_ADD_FETCHED,
  UI_REMOVE_FETCHED
} from '../mutation-types'
import { CONTENT_TYPES } from '@/utils/constants'
import contentTypeApi from '@/api/contentType'
import store from '@/store'
import merge from 'lodash.merge'
import findIndex from 'lodash.findindex'
import map from 'lodash.map'
import uniq from 'lodash.uniq'
import debounce from 'lodash.debounce'
import indexOf from 'lodash.indexof'
import union from 'lodash.union'

// empty object for storing debounce functions
const debounceManifest = {}

// this function check if debounceManifest contains certain key
// if no, creates a new lodash.debounce and stores a generic store.dispatch method
// in the debounce
const debounceDispatchGenerator = (name) => {
  if (debounceManifest[name]) return debounceManifest[name]

  debounceManifest[name] = debounce((action, payload) => {
    store.dispatch(action, payload)
  }, 3500)
  
  return debounceManifest[name]
}

const resultEntityFactory = () => {
  return {
    result: [],
    entities: {}
  }
}

const contentTypeFactory = (contentTypes = []) => {
  return contentTypes.reduce((outcome, value) => {
    outcome[value] = resultEntityFactory()

    return outcome
  }, {})
}

/**
 * Each contentType in Drupal has a "machine name"
 * That machine name is stored as a constant for each contentType in @/utils/constants
 * 
 * That machine name is then used for the following:
 * 1) the "key" in store.state.contentType (i.e. store.state.contentType.triggers)
 * 2) generic loadings states "fetching-triggers"
 * 3) all crud operations (fetch, get, create, read, update, delete), Drupal payload for any of these
 * operations need to know the content type
 * 
 * Here is a life cycle of a content type
 * 
 *  - When app boots, store.state.contentType.triggers is created (see below)
 *  - When Trigger.vue component mounts, dispatch('fetchContentType', 'triggers') is executed
 *  -- on response, it runs through a normalizer to format the resposne of all triggers in an object { entities, result } and saves that value
 *     back to store.state.contentType.triggers
 *  --- 'fetching-triggers' is temporarily put in store.state.ui.loadingStates = [] so UI can react accordingly
 *  - Trigger.vue component then has a computed property (vuex mapGetters) getContentTypeByType('triggers') which returns an array of 
 *    trigger content object return from the initial fetch
 *  - Think of a similar workflow for other CRUD features
 */
const state = {
  ...contentTypeFactory(CONTENT_TYPES),
  fetched: []
}

const getters = {
  // GENERIC

  getContentTypeById: (state, getters) => (type, id) => {
    return state[type].entities[id]
  },
  getContentTypeByNodeId: (state, getters) => (type, nodeId) => {
    const id = state[type].result.find(id => {
      return state[type].entities[id].nid == nodeId
    })

    return state[type].entities[id]
  },
  getContentTypeByType: (state, getters) => (type) => {
    const ids = state[type].result

    return map(ids, id => getters.getContentTypeById(type, id))
  },
  getSingleContentTypeByType: (state, getters) => (type) => {
    // only get the first content type entities
    // let id = state.quitPlan.result[0]
    // return state.quitPlan.entity[id]
    let {
      [type]: {
        result: [
          id // this by default will always be index 0
        ] = []
      } = {}
    } = state

    return getters.getContentTypeById(type, id)
  },
  getSingleContentTypeProperty: (state, getters) => (type, prop) => {
    let {
      [`${prop}`]: property,
    } = getters.getSingleContentTypeByType(type) || {}

    return property
  },
  isFetched: (state) => (value) => {
    return state.fetched.includes(value)
  }
}

const actions = {
  async fetchContentTypeAll ({ dispatch }, { type, filter, next }) {
    try {
      const { next: nextLink } = await dispatch('fetchContentType', {
        type,
        filter,
        next
      })

      return nextLink
        ? dispatch('fetchContentTypeAll', { type, filter, next: nextLink }) : 'SUCCESS'
    } catch (e) {
      throw e
    }
  },
  async fetchContentType({ commit, dispatch, getters}, { type, filter, next }) {
    try {
      let response = {}

      if (!getters.isFetched(`fetched-${type}`) || next) {
        response = await contentTypeApi.fetch(type, filter, next)

        commit(CONTENT_TYPE_UPDATE, { type, ...response })
        commit(UI_ADD_FETCHED, `fetched-${type}`)
      }

      return response
    } catch(e) {
      throw e
    }
  },

  async getContentType({ commit }, { type, id }) {
    try {
      let response = await contentTypeApi.get(type, id)

      commit(CONTENT_TYPE_UPDATE, { type, ...response })

      return
    } catch(e) {
      throw e
    }
  },

  async addContentType({ commit, dispatch }, { type, attributes }) {
    try {
      let response = await contentTypeApi.post(type, attributes)

      dispatch('contentTypeEvent', { type, action: 'add', label: attributes })

      commit(CONTENT_TYPE_UPDATE, { type, ...response })

      return
    } catch(e) {
      throw e
    }
  },

  async addContentWithDebounce({}, { type, attributes = {} }) {
    try {
      if (!type) throw new Error('addContentWithDebounce requires type and id parameters')

      let innerDebounce = debounceDispatchGenerator(`add-${type}`)

      // always cancel the debounce before initiating it again
      innerDebounce.cancel()
      innerDebounce('addContentType', { type, attributes })
    } catch(e) {
      throw e
    }
  },

  async updateContentType({ commit, dispatch }, { type, id, attributes = {} }) {
    try {
      let response = await contentTypeApi.patch(type, id, attributes)

      commit(CONTENT_TYPE_UPDATE, { type, ...response })

      dispatch('contentTypeEvent', { type, action: 'edit', label: attributes })

      return
    } catch(e) {
      throw e
    }
  },

  // this function is designed to:
  // FIRST update content in the store
  // THEN create debounce to anticipate more user updates
  // FINALLY call the debounce which will be the normal dispatch('updateContentType')
  // this creates functionality where the UI can be connectted directly to the store and not wait for server response
  async updateContentWithDebounce({ commit }, { type, id, attributes = {} }) {
    try {
      if (!id || !type) throw new Error('updateContentWithDebounce requires type and id parameters')

      let innerDebounce = debounceDispatchGenerator(id)
      // this fake response mirrors what contentApi.normalizeContentTypeResponse returns
      let fakeResponse = {
        result: [id],
        entities: {
          [`${id}`]: { ...attributes }
        }
      }

      commit(CONTENT_TYPE_UPDATE, { type, ...fakeResponse })

      // always cancel the debounce before initiating it again
      innerDebounce.cancel()
      innerDebounce('updateContentType', { type, id, attributes })
    } catch(e) {
      throw e
    }
  },

  async deleteContentType({ dispatch, commit, getters }, { type, id }) {
    try {      
      // capture title for analytics
      // this is assuming each content type has a title
      let { title } = getters.getContentTypeById(type, id)

      // this allows for smoother delete animations
      commit(CONTENT_TYPE_DELETE, { type, id })

      await contentTypeApi.delete(type, id)
      
      dispatch('contentTypeEvent', { type, action: 'delete', label: title })

      return
    } catch(e) {
      throw e
    }
  }
}

const mutations = {
  [CONTENT_TYPE_UPDATE](state, { type, result, entities }) {
    if (!type) throw new Error('CONTENT_TYPE_UPDATE mutation requires a type string')

    if (!state[type]) throw new Error(`${type} is not existing content type`)

    const currentResults = state[type].result
    const newResults = uniq(currentResults.concat(result))

    const currentEntities = state[type].entities
    const newEntities = merge({}, currentEntities, entities)

    Vue.set(state[type], 'result', newResults)
    Vue.set(state[type], 'entities', newEntities)
  },
  
  [CONTENT_TYPE_DELETE](state, { type, id }) {
    let index = findIndex(state[type].result, (innerId) => { return id == innerId })

    if (index !== -1) {
      Vue.delete(state[type].entities, id)
      Vue.delete(state[type].result, index)
    }
  },
  [UI_ADD_FETCHED](state, value) {
    state.fetched = union(state.fetched, [value])
  },
  [UI_REMOVE_FETCHED](state, value) {
    let workingCopy = state.fetched.slice()
    let index = indexOf(workingCopy, value)

    if (index !== -1) {
      workingCopy.splice(index, 1)
      state.fetched = workingCopy
    }
  },
}

export default { state, getters, actions, mutations }
