export const DEFAULT_LOCALE = 'en'
export const COOKIE_LOCALE = 'wib-locale'
export const SUPPORTED_LOCALES = { en: 'English', es: 'Spanish' }
export const SUPPORT_LOCALE_CODES = Object.keys(SUPPORTED_LOCALES)

export const COVID_BANNER_COOKIE = 'wib-covid-banner'

export const CONTENT_TYPES = [
  'airings',
  'story',
  'screening',
  'press',
  'community_based_organization'
]
// UI
export const TRAILER_YOUTUBE_ID = 'NbLlLUfL56k'
export const FEATURE_YOUTUBE_ID = 'km6azKlFTTQ'

// mailchimp
export const MAIL_CHIMP_SSM_KEY = 'wib_mailchimp_key'
export const MAIL_CHIMP_LIST = 'a830c7b06d'

export const PAGE_TITLE_SUFFIX = "THE WEST IS BURNING"
export const PAGE_TITLES = {
  home: `Home - ${PAGE_TITLE_SUFFIX}`,
  host: `Host - ${PAGE_TITLE_SUFFIX}`,
  'home-screening': `Host Private Screening - ${PAGE_TITLE_SUFFIX}`,
  'public-screening': `Host Public Screening - ${PAGE_TITLE_SUFFIX}`,
  storyMap: `Story Map - ${PAGE_TITLE_SUFFIX}`,
  support: `Supporters - ${PAGE_TITLE_SUFFIX}`,
  trailer: `Trailer - ${PAGE_TITLE_SUFFIX}`,
  join: `Join Us - ${PAGE_TITLE_SUFFIX}`,
  contact: `Contact Us - ${PAGE_TITLE_SUFFIX}`,
  connect: `Connect - ${PAGE_TITLE_SUFFIX}`,
  make: `Make Films - ${PAGE_TITLE_SUFFIX}`,
  watch: `Watch - ${PAGE_TITLE_SUFFIX}`
}
export const PAGE_DESCRIPTIONS = {
  home: 'THE WEST IS BURNING raises awareness about the conditions of western forests in the US, told through a full feature documentary',
  host: 'We invite you to sign up to host free public screenings of THE WEST IS BURNING in your community.',
  'home-screening': 'We invite you to sign up to host free home screenings of THE WEST IS BURNING in your community.',
  'public-screening': 'We invite you to sign up to host free public screenings of THE WEST IS BURNING in your community.',
  storyMap: 'Tour the western US to learn how wildfires are impacting lives and how people and systems are adapting to this new normal.',
  support: 'THE WEST IS BURNING was made possible by the generous support of these fine organizations and institutions.',
  trailer: 'Watch THE WEST IS BURNING Trailer',
  join: 'Get behind-the-scenes updates about the filming of THE WEST IS BURNING. Be the first to know when and where to watch it.',
  contact: 'The team behind THE WEST IS BURNING are eager to hear what you have to say. Send us a note and we\'ll get back to you right away.',
  connect: 'Learn about the many organizations across the West who are working hard to make fire resilient forests.',
  make: 'Using the innovative Filmstacker App, make your own short films from this documentary.',
  watch: 'There are several ways to watch THE WEST IS BURNING documentary film.'
}

export const socialMedias = [{
  id: 1,
  platform: "instagram",
  url: "www.instagram.com/thewestisburning/",
  icon: "wib_instagram",
},
{
  id: 2,
  platform: "twitter",
  url: "twitter.com/WestBurning",
  icon: "wib_twitter",
},
{
  id: 3,
  platform: "facebook",
  url: "www.facebook.com/westisburning/",
  icon: "wib_facebook",
}]

export const STATES = [
  "Alaska", 
  "Alabama", 
  "Arkansas", 
  "American Samoa", 
  "Arizona", 
  "California", 
  "Colorado", 
  "Connecticut", 
  "District of Columbia", 
  "Delaware", 
  "Florida", 
  "Georgia", 
  "Guam", 
  "Hawaii", 
  "Iowa", 
  "Idaho", 
  "Illinois", 
  "Indiana", 
  "Kansas", 
  "Kentucky", 
  "Louisiana", 
  "Massachusetts", 
  "Maryland", 
  "Maine", 
  "Michigan", 
  "Minnesota", 
  "Missouri", 
  "Mississippi", 
  "Montana", 
  "North Carolina", 
  "North Dakota", 
  "Nebraska", 
  "New Hampshire", 
  "New Jersey", 
  "New Mexico", 
  "Nevada", 
  "New York", 
  "Ohio", 
  "Oklahoma", 
  "Oregon", 
  "Pennsylvania", 
  "Puerto Rico", 
  "Rhode Island", 
  "South Carolina", 
  "South Dakota", 
  "Tennessee", 
  "Texas", 
  "Utah", 
  "Virginia", 
  "Virgin Islands", 
  "Vermont", 
  "Washington", 
  "Wisconsin", 
  "West Virginia", 
  "Wyoming"
]

export const COUNTRIES = [
  'Afghanistan',
  'Åland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua & Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia & Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Caribbean Netherlands',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo - Brazzaville',
  'Congo - Kinshasa',
  'Cook Islands',
  'Costa Rica',
  'Côte d’Ivoire',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard & McDonald Islands',
  'Honduras',
  'Hong Kong SAR China',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao SAR China',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar (Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'North Korea',
  'North Macedonia',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territories',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn Islands',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Réunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Samoa',
  'San Marino',
  'São Tomé & Príncipe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia & South Sandwich Islands',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'St. Barthélemy',
  'St. Helena',
  'St. Kitts & Nevis',
  'St. Lucia',
  'St. Martin',
  'St. Pierre & Miquelon',
  'St. Vincent & Grenadines',
  'Sudan',
  'Suriname',
  'Svalbard & Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad & Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks & Caicos Islands',
  'Tuvalu',
  'U.S. Outlying Islands',
  'U.S. Virgin Islands',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Wallis & Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]