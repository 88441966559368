import axios from 'axios'
import request from '@/api/utils/request'
import jscookie from 'js-cookie'

export const AUTH_CSRF_TOKEN = 'wib-csrf-token'

const auth = {
  getToken() {
    const url = `${process.env.VUE_APP_DRUPAL_API}/session/token?_format=json`

    return axios.get(url)
      .then(response => {
        let { data: csrf_token } = response

        jscookie.set(AUTH_CSRF_TOKEN, csrf_token, { expires: 7 })
        return csrf_token
      })
  }
}

export default auth