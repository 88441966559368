<template>
  <v-footer 
    height="auto" 
    fixed 
    color="accent"
    :class="getClasses"
    v-scroll="handleScroll"
    aria-label="Take Action Footer"
  >
    <slot></slot>
  </v-footer>
</template>

<script>
  export default {
    name: 'sticky-footer',

    mounted() {
    },

    props: {
      override: {
        type: Boolean,
        default: true
      },
      topOffset: {
        type: Number,
        default: 200
      },
      tolerance: {
        type: Number,
        default: 300
      }
    },

    data() {
      return {
        fromTop: 0,
        clientHeight: 0,
        totalHeight: 0,
        fromBottom: 0,
        scrollingUp: false,
        scrollingUpSnapshot: 0
      }
    },

    computed: {
      getClasses() {
        return {
          'sticky-footer': true,
          'sticky-footer--show': this.showFooter
        }
      },
      showFooter() {
        if (this.override === false) return false
        // if the page scroll is within 50px of the bottom, always show
        if (this.fromBottom < 50 && this.fromTop !== 0) {
          return true
        }

        // if page is scrolling up AND has been scrolling up for this.tolerance amount, hide footer
        if (this.scrollingUp) {
          this.scrollingUpSnapshot = this.scrollingUpSnapshot || this.fromTop

          if ((this.scrollingUpSnapshot - this.fromTop) > this.tolerance) {
            return false
          }
        } else {
          // if page is scrolling down, reset the scroll up "watcher"
          this.scrollingUpSnapshot = 0
        }

        // always check if footer is past the top offset, return true or false
        if (this.fromTop > this.topOffset) {
          return true
        } else {
          return false
        } 
      }
    },

    methods: {
      handleScroll(e) {
        this.setProperties(e.target.scrollingElement)
      },

      setProperties(el) {
        this.scrollingUp = (this.fromTop > el.scrollTop) ? true : false

        this.clientHeight = el.clientHeight
        this.fromTop = el.scrollTop
        this.totalHeight = el.offsetHeight
        this.fromBottom = (this.totalHeight - this.clientHeight) - this.fromTop
      }
    }
  }
</script>

<style lang="stylus">
  .sticky-footer
    position: fixed
    transition: all .3s ease
    transform: translateY(100%)
    max-width: 100vw

  .sticky-footer--show
    transform: translateY(0%)
</style>