<template>
  <!-- TODO: accessibility -->
  <div :class="getMainClasses" :style="getMainStyles" v-if="notPrerendering">
    <youtube-embed
      :videoId="videoId"
      :player-vars="getPlayerVars"
      player-width="640"
      player-height="352"
      :mute="mute || background"
      @ended="handleEnded"
      @paused="handlePaused"
      @ready="handleReady"
      @playing="handlePlaying">
    </youtube-embed>
    
    <transition name="fade-slow" mode="out-in">
      <div v-if="overlay && !killOverlay"
        :class="getOverlayClasses">
        <div class="youtube-video--overlay-bg"></div>
        <slot name="overlay" :player="player"></slot>
      </div>
    </transition>

    <transition name="fade-slow" mode="out-in">
      <div v-if="poster && !removePosterImage"
        class="youtube-video__poster"
        :style="getPosterStyles">
      </div>
    </transition>

    <slot></slot>
  </div>
  <!-- TODO: remove "more" videos at end -->
</template>

<script>
  export default {
    name: 'youtube-video',

    mounted() {
      
    },

    watch: {
      togglePlay: {
        handler: 'handleToggleVideo',
        immediate: true
      },
      mute: 'handleToggleVolume'
    },

    props: {
      crop: {
        type: [Number, Array],
        default: 0
      },
      background: Boolean,
      videoId: String,
      autoplay: Boolean,
      loop: Boolean,
      controls: Boolean,
      mute: Boolean,
      playsInline: Boolean,
      poster: Boolean,
      posterSrc: String,
      posterDelay: {
        type: [String, Number],
        default: 250
      },
      overlay: Boolean,
      togglePlay: Boolean,
      syncedPlayer: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        player: null,
        playing: false,
        videoReady: false,
        killOverlay: false,
        removePosterImage: false,
        notPrerendering: !window.__PRERENDER_INJECTED // TODO: this might not be needed
      }
    },

    computed: {
      getMainClasses() {
        return {
          'youtube-video': true,
          'youtube-video--crop': this.crop
        }
      },
      getMainStyles() {
        // the height of the iframe is also adjusted to 110% with crop
        const top = Array.isArray(this.crop) ? this.crop[0] : this.crop
        const bottom = Array.isArray(this.crop) ? this.crop[1] : this.crop

        return {
          marginTop: `-${top}px`
        }
      },
      getOverlayClasses() {
        return {
          'youtube-video__overlay': true
        }
      },
      getPosterStyles() {
        const imageUrl = (this.posterSrc) ? `url(${this.posterSrc})` : ''

        return {
          backgroundImage: imageUrl,
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          backgroundColor: '#000' // TODO - background color prop?
        }
      },
      getPlayerVars() {
        return {
          playsinline: Number(this.playsInline),
          modestbranding: (this.playsInline) ? 0 : 1,
          controls: Number(this.controls) || Number(this.background),
          autoplay: Number(this.autoplay) || Number(this.background),
          loop: Number(this.loop) || Number(this.background),
          ...((this.loop || this.background) ? { playlist: this.videoId } : {}),
          rel: 0,
          showinfo: 0,
        }
      }
    },

    methods: {
      handleReady({ target: player }) {
        this.videoReady = true
        this.$emit('update:syncedPlayer', player)

        this.player = player
        this.$emit('ready')
      },
      handlePlaying() {
        this.$emit('playing')
        this.playing = true
        this.killOverlay = (this.background) ? false : true

        setTimeout(() => {
          this.removePosterImage = true  
        }, this.posterDelay)
      },
      handleEnded() {
        this.$emit('ended')
      },
      handlePaused() {
        this.$emit('paused')
      },
      handleToggleVideo() {
        if (this.player) {
          this.togglePlay ? this.player.playVideo() : this.player.pauseVideo()
        }
      },
      handleToggleVolume(val) {
        if (this.player) {
          val ? this.player.mute() : this.player.unMute()
        }
      }
    }
  }
</script>

<style lang="stylus">
  .youtube-video
    overflow: hidden
    padding-bottom: 56.25%
    padding-top: 0
    padding-left: 0
    padding-right: 0
    position:relative
    height: 0

  .youtube-video iframe,
  .youtube-video object,
  .youtube-video embed
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    border: 0

  .youtube-video--crop
    @media(max-width: $md-breakpoint)
      padding-bottom: 70%
    
    @media(max-width: $sm-breakpoint)
      padding-bottom: 100%
    
    iframe,
    object,
    embed
      height: 110%
      @media(max-width: $md-breakpoint)
        margin-left: -25%
        margin-right: -25%
        width: 150%

      @media(max-width: $sm-breakpoint)
        margin-left: -50%
        margin-right: -50%
        width: 200%


  .youtube-video__overlay
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    transition: all .3s ease

  .youtube-video--overlay-bg
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    pointer-events: none
    z-index: 0
  
  .youtube-video__poster
    position: absolute
    background-color: $theme.primary
    width: 100%
    height: auto
    top: 0
    left: 0
    bottom: 0
</style>