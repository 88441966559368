<template>
  <div :class="getClasses" role="alert" aria-relevant="all">
    <v-icon large>{{ getIcon }}</v-icon>
    <span class="alert__message">
      <slot></slot>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'alert',

    props: {
      type: {
        type: String,
        validator(val) {
          const options = [
            'error',
            'success'
          ]

          return options.includes(val)
        },
        required: true
      }
    },

    computed: {
      getClasses() {
        const baseClass = 'alert'

        return {
          [`${baseClass}`]: true,
          [`${baseClass}--${this.type}`]: this.type
        }
      },
      
      getIcon() {
        const icons = {
          error: 'error',
          success: 'check_circle'
        }

        return icons[this.type]
      }
    }
  }
</script>

<style lang="stylus">
  .alert
    padding: 0 // TODO
    display: flex
    align-items: center
    font-size: 14px
    margin: 10px 0    

    .v-icon
      margin-right: 10px // TODO
    
    &--success
      .alert__message
        color: $theme.success
        font-weight: bold
      .v-icon
        color: $theme.success
    
    &--error
      .alert__message
        color: $theme.error
        font-weight: bold
      .v-icon
        color: $theme.error
    
</style>