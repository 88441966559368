import Vue from 'vue'
import VueI18n from 'vue-i18n'
import jscookie from 'js-cookie'
import { getLocale } from '@/utils/general'
import { COOKIE_LOCALE } from '@/utils/constants'

const enTranslations = require('@/lang/en.json')
// const esTranslations = require('@/lang/ui/es.json')

Vue.use(VueI18n)

// the only other place in the app that sets locale is in PublicFooter
// on drop down change
const locale = getLocale()
jscookie.set(COOKIE_LOCALE, locale)

const messages = {
  ...enTranslations,
  // ...esTranslations
}

const numberFormats = {
  'en': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  }
}

const i18n = new VueI18n({
  locale,
  fallbackLocale: 'en',
  messages,
  numberFormats
})

export default i18n
