<template>
  <v-footer
    height="auto"
    color="primary"
    class="secondary--text footer"
    aria-label="Main Footer"
  >
    <v-container fluid grid-list-lg class="footer__container">
      <v-layout wrap justify-start align-center>
        <v-flex md6>
          <h4>Connect</h4>
          <p>Sign up to receive periodic updates delivered right to your inbox.</p>
        </v-flex>
        <v-flex md5 offset-md1 style="text-align: right">
          <klayvio-btn
            class="ml-auto"
          />
          <!-- <footer-join-form class="text-md-right" /> -->
        </v-flex>
      </v-layout>
      <v-layout wrap row>
        <v-flex xs12 sm6 md4 class="footer__supporters">
          <h4>Support</h4>
          <p>Created by <a href="https://landmarkstories.arizona.edu" target="_blank" rel="noopner">Landmark Stories</a> at the University of Arizona in partnership with Wallowa Resources.</p>
          <p>Learn about <router-link :to="{ name: 'support' }">our supporters</router-link>.</p>
          <p>Donate to <a href="https://www.wallowaresources.org/donate-1" target="_blank">help our filmmakers</a>.</p>
        </v-flex>
        <v-flex xs12 sm6 md3 class="footer__contact">
          <h4>Say Hello</h4>
          <p class="mb-0">Wallowa Resources<br>
          401 NE First St. Suite A Enterprise, OR 97828<br>
          <a href="tel:541-426-8053">(541) 426-8053</a><br>
          <router-link :to="{ name: 'contact' }">Email Us</router-link></p>
        </v-flex>
        <v-flex xs12 sm5 md5 class="text-md-right">
          <candy-bar></candy-bar>
          <p class="footer__copyright">
            © {{ new Date().getFullYear() }} The Arizona Board of Regents | {{ version }}
          </p>
        </v-flex>
      </v-layout>
    </v-container>
  </v-footer>
</template>

<script>
import CandyBar from '@/components/CandyBar/CandyBar'
import KlayvioBtn from '@/components/KlayvioBtn/KlayvioBtn'
// import FooterJoinForm from '@/components/_forms/FooterJoinForm/FooterJoinForm'

export default {
  name: "footer-info",
  
  components: {
    CandyBar,
    KlayvioBtn,
    // FooterJoinForm
  },

  computed: {
    version () {
      return process.env.VERSION
        ? `v${process.env.VERSION.replace(/\"/g, '')}`
        : ''
    }
  }
}
</script>

<style lang="stylus">
  .footer
    .footer__container
      padding: 24px 25px 55px
      width: 100%
      
    .footer__contact
      padding-left: 0

    p
      font-size 14px
      line-height 1.5
      a
        color: $theme.secondary

    .footer__copyright
      font-size 10px
      
    @media(min-width:$md-breakpoint)
      padding: 24px 75px 100px

      .footer__contact
        padding-left: 48px !important
</style>