<template>
  <v-alert
    :value="covidAlert"
    color="tertiary"
    class="covid-alert"
    transition="slide-y-transition">
    <div class="covid-alert__content">
      <div>
        <strong>COVID-19</strong> When hosting or attending screenings, please adhere to current safety measures in your community.
      </div>
      <v-btn
        @click="handleCovidAlertDismiss"
        icon
      >
        <v-icon color="white">clear</v-icon>
      </v-btn>
    </div>
  </v-alert>
</template>

<script>
import jscookie from 'js-cookie'
import { COVID_BANNER_COOKIE } from '@/utils/constants'

export default {
  name: 'covid-alert',

  data () {
    return {
      covidAlert: true
    }
  },

  methods: {
    handleCovidAlertDismiss () {
      this.covidAlert = !this.covidAlert

      if (!this.covidAlert) {
        jscookie.set(COVID_BANNER_COOKIE, 'seen', { expires: 7 })
      }
    }
  }
}
</script>

<style lang="stylus">
  .covid-alert
    position: fixed
    top: 55px
    left: 0
    width: 100%
    z-index: 3
    padding: 10px 15px !important

    .covid-alert__content
      display: flex
      align-items: center
      justify-content: space-between

    @media(max-width: $md-breakpoint)
      top: 40px
</style>