import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Scroll } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  iconfont: 'md',
  icons: {
    'wib_cost': 'icomoon icomoon-cost',
    'wib_facebook': 'icomoon icomoon-facebook',
    'wib_hot_fires': 'icomoon icomoon-hot-fires',
    'wib_instagram': 'icomoon icomoon-instagram',
    'wib_mega_fires': 'icomoon icomoon-mega-fires',
    'wib_twitter': 'icomoon icomoon-twitter'
  },
  directives: {
    Scroll
  },
  theme: {
    primary: '#3E4143', // dark grey
    secondary: '#F5F4ED', // off white
    accent: '#CE5A22', // orange
    error: '#CE5A22', // orange
    success: '#93AF80', // green
    info: '#D99574', // light orange
    tertiary: '#37A3B7'
  }
})
