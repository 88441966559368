import Vue from 'vue'
import router from '@/router'
import VueGtm from 'vue-gtm'

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_CONTAINER, // Your GTM ID
  enabled: (window && !window.__PRERENDER_INJECTED) && (process.env.NODE_ENV === 'production'), // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  // enabled: true, // uncomment to test on dev
  debug: process.env.NODE_ENV !== 'production', // Whether or not display console logs debugs (optional)
  vueRouter: router // Pass the router instance to automatically sync with router (optional)
  //ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
})