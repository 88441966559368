import Vue from 'vue'
import Vuex from 'vuex'

import contentType from './modules/contentType'
import general from './modules/general'
import gtm from './modules/gtm'
import ui from './modules/ui'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  actions: {},
  modules: {
    contentType,
    general,
    gtm,
    ui
  }
})
