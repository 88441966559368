import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

// https://github.com/AurityLab/vue-recaptcha-v3
if (!window.__PRERENDER_INJECTED) {
  Vue.use(VueReCaptcha, {
    siteKey: '6LcJrs8UAAAAANVfZXPyFA0qHxIiWMb-BXhoTeK3',
    loaderOptions: {
      autoHideBadge: true
    }
  })
}
