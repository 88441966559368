import Leaflet from 'leaflet'
import jscookie from 'js-cookie'
import { COOKIE_LOCALE, SUPPORT_LOCALE_CODES, DEFAULT_LOCALE } from '@/utils/constants'

export const resetObject = (obj = {}) => {
  let innerObject = { ...obj }

  for (var k in innerObject) {
    if (innerObject.hasOwnProperty(k)) {
      innerObject[k] = null
    }
  }

  return innerObject
}

// returns the 'ash-locale' cookie or defaults to 'en'
export const getLocale = () => {
  let lang = jscookie.get(COOKIE_LOCALE)

  return SUPPORT_LOCALE_CODES.includes(lang)
    ? lang
    : DEFAULT_LOCALE
}

export const mergeDefaultLeafletIcon = (iconObject = {}) => {
  let newIcon = {
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [25, 41],
    shadowSize: [41, 41],
    iconAnchor: [12.5, 41],
    shadowAnchor: [13.1, 41],
    popupAnchor: [2, -33],
    ...iconObject
  }

  return Leaflet.icon(newIcon)
}

export const encodeAppName = (app) => {
  const t = String(new Date().getTime()).split('').reverse()
  const a = String(app).slice(0, 3).split('').map(v => v.charCodeAt(0))
  const k = [0, 5, 10].reduce((o, v, i) => { o.splice(v, 0, a[i]); return o }, t)
  return k.join(`.`)
}
