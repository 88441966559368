<template>
  <div :class="getClasses">
    <h1 v-if="!noHeader" v-html="headerText"></h1>
    <v-container grid-list-lg class="view-wrapper__body pa-0">
      <slot></slot>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'view-wrapper',
    
    props: {
      headerText: String,
      noHeader: Boolean,
      noOffset: Boolean
    },

    data() {
      return {

      }
    },

    computed: {
      getClasses() {
        return {
          'view-wrapper': true,
          'view-wrapper--no-offset': this.noOffset
        }
      }
    }
  }
</script>

<style lang="stylus">
  .view-wrapper
    padding 5%
    margin-bottom 70px
    position: relative

    .view-wrapper__body
      max-width: 600px
    
    @media(min-width: $sm-breakpoint)
      max-width: 600px
      margin-right auto
      margin-left auto
      margin-bottom 80px
      padding 16px
      margin-top 20px
      
      h1
        margin-bottom: 40px
    
    @media(min-width: $md-breakpoint)
        max-width: 850px

      h1
        max-width: 600px
      
      .view-wrapper__body
        max-width: 675px
        margin-right auto
        margin-left auto
      
      @media(min-width: $lg-breakpoint)
        max-width: 1000px
        margin-top 40px

  .view-wrapper--no-offset
    .view-wrapper__body
      max-width: 100%
</style>