import request from '@/api/utils/request'
// TODO: remove dependency on lodash in this file
import reduce from 'lodash.reduce'
import forEach from 'lodash.foreach'
import queryString from 'query-string'

const BASE_URL = `/jsonapi/node`

// this helper function was only used to overwrite Content-Type header
const jsonApi = (payload = {}) => {
  let {
    headers = {}
  } = payload

  const newHeaders = Object.assign({}, headers, {
    'Content-Type': 'application/vnd.api+json',
  })

  return request({ ...payload, headers: newHeaders })
}

export function normalizeContentTypeResponse(data = [], included = []) {
  function attributeConversion(attributes) {
    let attributeCopy = { ...attributes }
  
    // provide this an array of functiontions to normalize each individual attr if needed
    let conversions = [
      {
        key: 'path',
        fn: (path = {}) => {
          const { alias = '' } = path

          return alias && alias.substring(0, 1) === '/' ? alias.substring(1) : alias
        }
      }
    ]
  
    forEach(conversions, (obj) => {
      let { key = '', fn } = obj
      
      if (key in attributeCopy) { attributeCopy[key] = fn(attributeCopy[key]) }
    })
  
    return attributeCopy
  }

  function getRelationshipFields (relationships) {
    const includesToBeMerged = {}

    for (let key in relationships) {
      if (relationships[key].data) {
        const {
          data: {
            type,
            id
          } = {}
        } = relationships[key]
  
        const {
          attributes = {},
          relationships: nestedRelationships = {}
        } = included.find(inc => {
          return inc.type === type
            && inc.id === id
        }) || {}
  
        includesToBeMerged[key] = {
          ...attributes,
          ...getRelationshipFields(nestedRelationships)
        }
      }
    }

    return includesToBeMerged
  }

  try {
    let innerData = (Array.isArray(data))
      ? data
      : [data]

    return reduce(innerData, (outcome, item) => {
      let {
        id, // id assigned by jsonapi
        attributes, // has an "drupal_internal__nid", not sure if being used by anything
        relationships = {}
      } = item

      outcome.result.push(id)
      outcome.entities[id] = attributeConversion({
        ...attributes,
        ...getRelationshipFields(relationships)
      })
      outcome.entities[id].id = id

      return outcome
    }, { result: [], entities: {} })
  } catch(e) {
    console.log(e)
    throw new Error('Problem encountered while normalizing content type: ', e)
  }
}

const createPayload = (type, attributes, id) => {
  const NODE_TYPE = `node--${type}`

  return {
    url: (id) ? `${BASE_URL}/${type}/${id}` : `${BASE_URL}/${type}`,
    data: {
      data: {
        type: NODE_TYPE,
        id,
        attributes
      }
    }
  }
}

const contentTypeApi = {
  /**
   * Wrapper for simple fetches with the JSON API. This function accepts a filter object with 
   * parameterizes it with the npm package 'query-string'
   * 
   * @param {string} type content type machine name 
   * @param {Object} filter filtering, sorting, limiting values for querying JSON:API https://www.drupal.org/docs/8/modules/jsonapi/jsonapi
   * @param {*} next pagination URL - this will override type and filter since it is a full URL
   */
  fetch(type = '', filter = {}, next = '') {
    if (!type) throw new Error('Content Fetch: Missing required type parameter')

    const url = next
      ? next
      : filter
        ? `${BASE_URL}/${type}?${queryString.stringify(filter)}`
        : `${BASE_URL}/${type}`

    return request({ url })
      .then(response => {
        let {
          data: {
            data = [],
            included = [],
            links: {
              next: {
                href = ''
              } = {}
            } = {}
          } = {}
        } = response
        
        return {
          ...normalizeContentTypeResponse(data, included),
          next: href
        }
      })
  },
  get(type, id, filter = {}) {
    if (!type || !id) throw new Error('Content Get: Missing type or id parameter value')
    
    const url = (filter)
      ? `${BASE_URL}/${type}/${id}?${queryString.stringify(filter)}`
      : `${BASE_URL}/${type}/${id}`

    return request({ url })
      .then(response => {
        let {
          data: {
            data = {},
            included = []
          } = {}
        } = response
        
        return normalizeContentTypeResponse(data, included)
      })
  },
  post(type, attributes = {}) {
    if (!type) throw new Error('Content Add: Missing required type parameter')
    
    const payload = createPayload(type, attributes)

    return jsonApi({ ...payload, method: 'post' })
      .then(response => {
        let {
          data: {
            data = {},
            included = []
          } = {}
        } = response
        
        return normalizeContentTypeResponse(data, included)
      })
  },
  patch(type, id, attributes = {}) {
    if (!type || !id) throw new Error('Content Patch: Missing type or id parameter value')
    
    const payload = createPayload(type, attributes, id)

    return jsonApi({ ...payload, method: 'patch' })
      .then(response => {
        let {
          data: {
            data = {},
            included = []
          } = {}
        } = response
        
        return normalizeContentTypeResponse(data, included)
      })
  },
  delete(type, id) {
    if (!type || !id) throw new Error('Content Delete: Missing type or id parameter value')
    
    return request({ url: `${BASE_URL}/${type}/${id}`, method: 'delete' })
      .then(response => {
        let {
          data: {
            data = {}
          } = {}
        } = response
        
        return data
      })
  }
}

export default contentTypeApi
