<template>
  <div :class="getClasses">
    <div class="filter-list__collapse-wrapper">
      <collapse class="filter-list__collapse" ref="collapse" v-if="!noFilter" v-model="watchCollapse">
        <template v-slot:trigger>
          <div class="filter-list__collapse-trigger">
            <div class="filter-list__title" v-if="$slots['title']">
              <slot name="title"></slot>
            </div>

            <v-btn icon class="common--text"><v-icon>filter_list</v-icon></v-btn>
          </div>
        </template>

        <template v-slot:content>
          <v-text-field
            class="filter-list__search mt-2"
            @input="filterDebounce"
            :label="searchLabel"
            outline
            single-line
            ref="filterInput"/>

          <div class="filter-list__options" v-if="$slots['menu']">
            <slot name="menu"></slot>
          </div>
        </template>
      </collapse>
    </div>

    <slot name="loading" v-if="loading">
      <v-progress-linear v-bind:indeterminate="true" color="grey"></v-progress-linear>
    </slot>

    <ul v-if="groupBy" 
      class="filter-list__list" 
      v-infinite-scroll="loadMore" 
      infinite-scroll-distance="50" 
      :infinite-scroll-disabled="loading"
      :infinite-scroll-immediate-check="false"
      v-show="!loading">
      <template v-for="(group, key) in groupedItems">
        <slot name="group-headings" :heading="key"></slot>
        <slot name="list"
          v-for="item in group"
          :item="item">
        </slot>
      </template>

      <slot v-if="isEmpty(groupedItems)" name="empty-item" class="filter-list__list-empty">
        <em>No items in this list matched your search.</em>
      </slot>
    </ul>

    <ul v-else 
      class="filter-list__list" 
      v-infinite-scroll="loadMore" 
      infinite-scroll-distance="50" 
      :infinite-scroll-disabled="loading"
      :infinite-scroll-immediate-check="false"
      v-show="!loading">
      <slot name="list"
        v-for="item in filteredItems"
        :item="item">
      </slot>

      <slot name="empty-item" v-if="!filteredItems.length" class="filter-list__list-empty">
        <em>No items in this list matched your search.</em>
      </slot>
    </ul>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce'
  import groupBy from 'lodash.groupby'
  import isEmpty from 'lodash.isempty'

  let FilterList = {
    name: 'filter-list',

    props: {
      /**
       * Array of objects that make up the content
       * in each list item
       */
      items: {
        type: Array,
        default() {
          return []
        },
        required: true
      },
      /**
       * Appears as placeholder in filter input field
       */
      searchLabel: String,
      /**
       * hide/shows $slots['loading']
       */
      loading: Boolean,
      delay: {
        type: [String, Number],
        default: 500
      },
      itemsPerPage: {
        type: [Number, String],
        default: 20
      },
      noFilter: {
        type: Boolean,
        default: false
      },
      quickFilterFieldName: String,
      quickFilterPredicates: {
        type: Array,
        default: () => {
          return []
        }
      },
      sortBy: Object,
      groupBy: {
        type: [String, Boolean],
        default: false
      }
    },

    watch: {
      filterValue() {
        this.page = 1
      },
      watchCollapse(val) {
        if (val) {
          setTimeout(() => {
            this.$refs.filterInput.$refs.input.focus()
            this.$refs.filterInput.$el.click()
          }, 650)
        }
      }
    },

    data() {
      return {
        filterValue: '',
        filterDebounce: debounce(this.handleFilter, this.delay),
        page: 1,
        isEmpty,
        watchCollapse: false
      }
    },

    computed: {
      sortedItems() {
        if (!this.sortBy) return this.items.slice()
        
        let { field, order } = this.sortBy
        let innerItems = this.items.slice()

        return innerItems.sort((a, b) => {
          return (a[field] > b[field])
            ? (order === 'asc') ? 1 : -1
            : (order === 'asc') ? -1 : 1
        })
      },
      filteredItems() {
        return this.filterItems(this.sortedItems).slice(0, this.itemsCount)
      },
      groupedItems() {
        const groups = groupBy(this.filterItems(this.sortedItems), this.groupBy)

        const slicedGroups = groups.reduce((outcome, group) => {
          return (Array.isArray(group)) ? outcome.concat(group) : outcome
        }, []).slice(0, this.itemsCount)

        return groupBy(slicedGroups, this.groupBy)

        // return groupBy(this.filteredItems, this.groupBy)
      },
      itemsCount() {
        const count = this.page * this.itemsPerPage

        return (count >= this.sortedItems.length) 
          ? this.sortedItems.length
          : this.page * this.itemsPerPage
      },
      getClasses() {
        return {
          'filter-list': true,
          'filter-list--with-menu': !this.noFilter
        }
      }
    },

    methods: {
      handleFilter(val) {
        this.filterValue = val.trim().toLowerCase()
      },
      loadMore() {
        this.page = this.page + 1
      },
      filterItems(items) {
        return items.filter((item) => {
          let searchMe = Object.values(item).toString().toLowerCase()

          let arrayOfPassingFieldConditions = this.quickFilterPredicates.map((value) => {
            return (typeof value === 'function')
              ? value(item)
              : true
          })

          /**
            This could be a version if we wanted to pass in field columns

            let arrayOfPassingFieldConditions = this.quickFilterValues.map((value) => {
              return (typeof value === 'string')
                ? !!(item[this.quickFilterFieldName].toLowerCase() === value.toLowerCase())
                : value(item[this.quickFilterFieldName])
            })
           */

          let meetsConditions = (this.quickFilterPredicates.length)
            ? arrayOfPassingFieldConditions.includes(true)
            : true

          return searchMe.includes(this.filterValue) && meetsConditions
        })
      }
    }
  }

  export default FilterList
</script>

<style lang="stylus">  
  .filter-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    
    .progress-linear {
      margin: 0;
    }
    
    .filter-list__title {
      text-transform: uppercase;
      margin-right: auto;
      font-size: 16px;
    }
  }

  .filter-list__collapse-wrapper {
    position: relative;
  }

  .filter-list__icon-label {
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 5px;
  }

  .filter-list__collapse-trigger {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    .v-btn {
      margin: 0;
    }
  }
  
  .filter-list__collapse {
    width: 100%;
    
    .input-group__details {
      height: 0;
      min-height: 0;
    }

    .subheader {
      padding: 5px 0 0 ;
      height: auto;
    }
    
    .radio-group {
      padding: 0;
    }
  }
  
  .filter-list__options {
    padding: 10px;
  }
  
  .filter-list__list {
    padding-left: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    flex: 1;
    list-style: none;
    margin: 0;
    ::-webkit-scrollbar {
      display: none
    }
  }
  
  .filter-list__list-empty {
    pointer-events: none;
  }
</style>