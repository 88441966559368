<template>
  <div class="recaptcha-policy grey--text">
    This site is protected by reCAPTCHA and the Google
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
  </div>
</template>

<script>
  export default {
    name: 'recaptcha-policy'
  }
</script>
