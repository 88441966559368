<template>
  <v-layout
    justify-space-between
    column
    fill-height
  >
    <v-flex>
      <nav aria-label="Main Navigation">
        <v-list class="main-menu">
          <a href="#main-content" class="main-menu__skip">Skip to main content</a>
          <v-list-tile :to="{ name: 'storyMap' }" exact class="">
            <v-list-tile-content>
              <v-list-tile-title>Story Map</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="{ name: 'make' }" exact class="">
            <v-list-tile-content>
              <v-list-tile-title>Make Films</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="{ name: 'watch' }" exact class="">
            <v-list-tile-content>
              <v-list-tile-title>Watch</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="{ name: 'host' }" exact class="">
            <v-list-tile-content>
              <v-list-tile-title>Host</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="{ name: 'connect' }" exact class="">
            <v-list-tile-content>
              <v-list-tile-title>Connect</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="{ name: 'press' }" exact class="">
            <v-list-tile-content>
              <v-list-tile-title>Press</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider class="my-1"></v-divider>
          <div class="nav_sub">
            <v-list-tile :to="{ name: 'trailer' }" exact>
              <v-list-tile-content>
                <v-list-tile-title>Trailer</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile :to="{ name: 'contact' }" exact>
              <v-list-tile-content>
                <v-list-tile-title>Contact</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile :to="{ name: 'home' }" exact>
              <v-list-tile-content class="pb-0">
                <v-list-tile-title>Home</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </div>
        </v-list>
      </nav>
    </v-flex>
    <v-flex shrink>
      <candy-bar></candy-bar>
    </v-flex>
  </v-layout>
</template>

<script>
  import CandyBar from '@/components/CandyBar/CandyBar'

  export default {
    name: 'main-menu',
    
    components: {
      CandyBar
    }
  }
</script>

<style lang="stylus">
  .main-menu
    padding-top 30px

    .main-menu__skip
      text-decoration: none
      color: $theme.primary !important
      font-size: 8px
      position: absolute

    .v-list__tile--link:hover, .v-list__tile--highlighted, .v-list__group__header:hover
      background none !important

    .v-list__tile
      padding 20px
      height auto
      text-transform uppercase

    .v-list__tile__title 
      height 30px
      font-size: 30px
      line-height: 30px
      color: $theme.secondary
      font-weight: bold

    hr
      margin 0 20px
      border-color: $color-middle-grey !important

    .nav_sub
      .v-list__tile--link:hover, .v-list__tile--highlighted, .v-list__group__header:hover

      .v-list__tile
        padding-bottom 0
        
      .v-list__tile__title
        font-weight normal
        color: $color-light-grey
        font-size: 18px
          
</style>